import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchNumberFormat, updateNumberFormat } from 'redux/actions/numberFormats';
import { useCurrentNumberFormatSelector } from 'redux/selectors/numberFormats';
import routes from 'routes';

import { NumberFormatEditionForm } from 'components/numberFormats';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name NumberFormatEditionPage
 * @description A page used to edit an existing number format.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 */
const NumberFormatEditionPage = () => {
	const dispatch = useDispatch();
	const { id: numberFormatId } = useParams();
	const { t } = useTranslation();

	const numberFormat = useCurrentNumberFormatSelector(numberFormatId);

	const canEditNumberFormat = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	useEffect(() => {
		dispatch(fetchNumberFormat(numberFormatId));
	}, [dispatch, numberFormatId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The updated values from NumberFormatEditionForm.
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditNumberFormat) {
			dispatch(updateNumberFormat(formData, numberFormatId, routes.settings.numberFormats.list));
		} else {
			redirectOnSuccess(routes.settings.numberFormats.list);
		}
	}, [canEditNumberFormat, dispatch, numberFormatId]);

	return (
		numberFormat
			? <NumberFormatEditionForm numberFormat={numberFormat} onSubmit={handleFormSubmit} />
			: <p>{t('number_format.edition.loading')}</p>
	);
};

export default NumberFormatEditionPage;
