import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ContactTabs } from 'constants/contactEnum';
import PropTypes from 'prop-types';
import { fetchAllForContact } from 'redux/actions/contacts/contacts';
import { addRelationType } from 'redux/actions/relationTypes';
import { addTag } from 'redux/actions/tags';
import { useAllForContactSelector, useContactsLoadingSelector } from 'redux/selectors/contacts/contacts';

import { ContactPositionTagCreationForm, ContactTitleTagCreationForm } from 'components/contacts/contactTags';
import { RelationTypeCreationForm } from 'components/relationTypes';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';

import { Address, ContactIdentifier, PhoneMail } from './contactTabs';

/**
 * @name ContactCreationForm
 * @description A form used to create a new contact.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit		The method to trigger upon form submission.
 * @param {boolean}	[inSuperAdmin]	Whether the form is being displayed inside the super-admin panel or not, default to false.
 * @param {object}	[targetPartner]	The targetPartner if creation occurs from targetPartner environment.
 */
const ContactCreationForm = ({ onSubmit, inSuperAdmin, targetPartner }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForContactForm = useAllForContactSelector();
	const positions = useMemo(() => allForContactForm?.positions ?? [], [allForContactForm]);
	const titles = useMemo(() => allForContactForm?.titles ?? [], [allForContactForm]);
	const partners = useMemo(() => allForContactForm?.partners ?? [], [allForContactForm]);
	const relationTypes = useMemo(() => allForContactForm?.relationTypes ?? [], [allForContactForm]);

	const isLoading = useContactsLoadingSelector();

	const { formProps, buttonProps } = useSubmitButton();

	const tabsToDisplay = useMemo(() => {
		const tabs = [];
		Object.entries(ContactTabs).forEach(([, value]) => {
			tabs.push(value);
		});

		return tabs;
	}, []);

	const relationTypeCreationModal = useFormModal(RelationTypeCreationForm, t('pages.common_modules.relation_types.creation'), fetchAllForContact, addRelationType);
	const titleCreationModal = useFormModal(ContactTitleTagCreationForm, t('pages.common_modules.contacts.tag.creation.title'), fetchAllForContact, addTag);
	const positionCreationModal = useFormModal(ContactPositionTagCreationForm, t('pages.common_modules.contacts.tag.creation.position'), fetchAllForContact, addTag);

	const [selectedContactTabId, setSelectedContactTabId] = useState('identity-tab');

	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedContactTabId(tabId);
	}, []);

	useEffect(() => {
		dispatch(fetchAllForContact(inSuperAdmin));
	}, [dispatch, inSuperAdmin]);

	const handleSubmit = useCallback((formData) => {
		// The backend responds with 400 Bad Request if we send an empty phone line, but we can't make phones required
		const result = {
			...formData,
			phones: formData.phones?.filter(({ type, number }) => type && number),
		};

		onSubmit(result);
	}, [onSubmit]);

	return (
		<DynamicForm
			onSubmit={handleSubmit}
			cleanFormData
			{...formProps}
		>
			<TabList
				defaultSelectedTabId={selectedContactTabId}
				onTabSelectionChanged={onTabSelectionChanged}
			>
				{tabsToDisplay.map((tabId) => (
					<Tab key={tabId} tabId={`${tabId}-tab`} tabPanelId={`${tabId}-tabpanel`} dontDisplayCloseButton>
						{t(`contact.fields.${tabId}`)}
					</Tab>
				))}
			</TabList>

			<TabPanel
				tabId="identity-tab"
				tabPanelId="identity-tabpanel"
				hidden={selectedContactTabId !== 'identity-tab'}
			>
				<ContactIdentifier
					inSuperAdmin={inSuperAdmin}
					isLoading={isLoading}
					partners={partners}
					positionCreationModal={positionCreationModal}
					positions={positions}
					relationTypeCreationModal={relationTypeCreationModal}
					relationTypes={relationTypes}
					targetPartner={targetPartner}
					titleCreationModal={titleCreationModal}
					titles={titles}
				/>
			</TabPanel>

			<TabPanel
				tabId="address-tab"
				tabPanelId="address-tabpanel"
				hidden={selectedContactTabId !== 'address-tab'}
			>
				<Address />
			</TabPanel>

			<TabPanel
				tabId="phone_mail-tab"
				tabPanelId="phone_mail-tabpanel"
				hidden={selectedContactTabId !== 'phone_mail-tab'}
			>
				<PhoneMail />
			</TabPanel>

			<Button className="primary" type="submit" {...buttonProps}>{t('contact.creation.action')}</Button>
		</DynamicForm>
	);
};

ContactCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	inSuperAdmin: PropTypes.bool,
	targetPartner: PropTypes.object,
};

ContactCreationForm.defaultProps = {
	inSuperAdmin: false,
	targetPartner: undefined,
};

export default ContactCreationForm;
