import { AccessRights } from 'lib/shared/accessRights';
import { AccessDefinitionCreationPage, AccessDefinitionEditionPage, AccessDefinitionListPage } from 'pages/accessDefinitions';
import { AccountCreationPage, AccountEditionPage, AccountListPage } from 'pages/accounts';
import { ContactCreationPage, ContactEditionPage, ContactListPage } from 'pages/contacts';
import { ExpenseCreationPage, ExpenseEditionPage, ExpenseListPage, ExpensePreCreationPage, ExpenseVisualisationPage } from 'pages/expenses';
import { HistoryCreationPage, HistoryEditionPage, HistoryListPage, HistoryReminderPage } from 'pages/histories';
import { HomePage } from 'pages/home';
import { InvoiceCreationPage, InvoiceDashboard, InvoiceEditionPage, InvoiceListPage, InvoicePreCreationPage, InvoiceVisualisationPage } from 'pages/invoices';
import { MediaListPage } from 'pages/mediaManager';
import { NumberFormatCreationPage, NumberFormatEditionPage, NumberFormatListPage } from 'pages/numberFormats';
import { OfferCreationPage, OfferEditionPage, OfferListPage } from 'pages/offers';
import { PartnerCreationPage, PartnerEditionPage, PartnerListPage } from 'pages/partners';
import { PaymentDelayCreationPage, PaymentDelayEditionPage, PaymentDelayListPage } from 'pages/paymentDelays';
import { ProjectCreationPage, ProjectEditionPage, ProjectListPage } from 'pages/projects';
import { QuotationCreationPage, QuotationEditionPage, QuotationListPage, QuotationPreCreationPage, QuotationVisualisationPage } from 'pages/quotations';
import { RegistrationCreationPage, RegistrationEditionPage, RegistrationListPage } from 'pages/registrations';
import { RelationTypeCreationPage, RelationTypeEditionPage, RelationTypeListPage } from 'pages/relationTypes';
import { SectorCreationPage, SectorEditionPage, SectorListPage } from 'pages/sectors';
import { StatusCreationPage, StatusEditionPage, StatusListPage } from 'pages/statuses';
import { StatusObjectCreationPage, StatusObjectEditionPage, StatusObjectListPage } from 'pages/statusObjects';
import { AdminDashboardPage, AdminSettingsPage, CompanyCreationPage, CompanyEditionPage, CompanyListPage } from 'pages/superadmin';
import { TargetingCreationPage, TargetingEditionPage, TargetingListPage } from 'pages/targetings';
import { TargetPartnersListPage } from 'pages/targetings/targetPartners';
import { ImportTargetPartnersPage, TargetCreationPage, TargetDetailsPage, TargetEditionPage, TargetListPage } from 'pages/targetings/targets';
import {
	TemplateExportListPage,
	TemplateListPage,
	TemplatePdfEditionPage,
	TemplatePdfExportPage,
	TemplatePdfReexportPage,
	TemplatePdfViewPage,
	TemplatePreCreationPage,
	TemplateSpreadSheetViewPage,
} from 'pages/templates';
import { ExportPage } from 'pages/templates/export';
import { TextObjectCreationPage, TextObjectEditionPage, TextObjectListPage } from 'pages/textObjects';
import { TimesheetCreationPage, TimesheetEditionPage, TimesheetListPage } from 'pages/timesheets';
import { DefinePasswordPage, ForgotPasswordPage, LogoutPage, SignInPage, UserCreationPage, UserEditionPage, UserListPage, UserPreferencesPage } from 'pages/users';
import { VatRateCreationPage, VatRateEditionPage, VatRateListPage } from 'pages/vatRates';

import keys from './keys';

/**
 * Routes to render
 * For authentication purposes, each route can have `allowAnonymous` and `allowAuthenticated` values
 * Default values are :
 *  - `allowAnonymous`		: false
 *  - `allowAuthenticated`	: true
 * For access rights and roles purposes, each route can have a `superAdmin` and an `accessRight` value
 * `superAdmin` restricts the page to a superAdmin
 * `accessRight` restricts the page or the routes group to users with the specified access right
 * Default value is :
 *  - `superAdmin`			: false
 *  - `accessRight`			: undefined
 */
export default [
	{
		path: keys.app.default,
		exact: true,
		component: HomePage,
	},
	// Auth
	{
		path: keys.auth.default,
		routes: [
			{
				path: keys.auth.login,
				allowAnonymous: true,
				allowAuthenticated: false,
				exact: true,
				component: SignInPage,
			},
			{
				path: keys.auth.logout,
				exact: true,
				component: LogoutPage,
			},
			{
				path: keys.auth.forgottenPassword,
				allowAnonymous: true,
				allowAuthenticated: false,
				exact: true,
				component: ForgotPasswordPage,
			},
		],
	},
	// Accounting
	{
		path: keys.accounting.default,
		accessRight: AccessRights.accounting.VIEW,
		routes: [
			// Accounts
			{
				path: keys.accounting.accounts.default,
				accessRight: AccessRights.accounting.accounts.VIEW,
				routes: [
					{
						path: keys.accounting.accounts.list,
						accessRight: AccessRights.accounting.accounts.VIEW,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: AccountListPage,
					},
					{
						path: keys.accounting.accounts.accountCreation,
						accessRight: AccessRights.accounting.accounts.enhancedRights.CREATE_ACCOUNT,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: AccountCreationPage,
					},
					{
						path: keys.accounting.accounts.accountEdition,
						accessRight: AccessRights.accounting.accounts.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: AccountEditionPage,
					},
				],
			},
			// Invoices
			{
				path: keys.accounting.invoices.default,
				accessRight: AccessRights.accounting.invoices.VIEW,
				routes: [
					{
						path: keys.accounting.invoices.list,
						accessRight: AccessRights.accounting.invoices.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: InvoiceListPage,
						exact: true,
					},
					{
						path: keys.accounting.invoices.invoiceCreation,
						accessRight: AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: InvoiceCreationPage,
						exact: true,
					},
					{
						path: keys.accounting.invoices.invoiceEdition,
						accessRight: AccessRights.accounting.invoices.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: InvoiceEditionPage,
						exact: true,
					},
					{
						path: keys.accounting.invoices.invoicePreCreation,
						accessRight: AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: InvoicePreCreationPage,
						exact: true,
					},
					{
						path: keys.accounting.invoices.invoiceVisualisation,
						accessRight: AccessRights.accounting.invoices.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: InvoiceVisualisationPage,
					},
					{
						path: keys.accounting.invoices.InvoiceDashboard,
						accessRight: AccessRights.accounting.invoices.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: InvoiceDashboard,
						exact: true,
					},
				],
			},
		],
	},
	// Common modules
	{
		path: keys.commonModules.default,
		accessRight: AccessRights.commonModules.VIEW,
		routes: [
			// Contacts
			{
				path: keys.commonModules.contacts.default,
				accessRight: AccessRights.commonModules.contacts.VIEW,
				routes: [
					{
						path: keys.commonModules.contacts.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ContactListPage,
						accessRight: AccessRights.commonModules.contacts.VIEW,
					},
					{
						path: keys.commonModules.contacts.contactCreation,
						accessRight: AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: ContactCreationPage,
					},
					{
						path: keys.commonModules.contacts.contactEdition,
						accessRight: AccessRights.commonModules.contacts.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: ContactEditionPage,
					},
				],
			},
			// Partners
			{
				path: keys.commonModules.partners.default,
				accessRight: AccessRights.commonModules.partners.VIEW,
				routes: [
					{
						accessRight: AccessRights.commonModules.partners.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: PartnerListPage,
						exact: true,
						path: keys.commonModules.partners.list,
					},
					{
						accessRight: AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: PartnerCreationPage,
						exact: true,
						path: keys.commonModules.partners.partnerCreation,
					},
					{
						accessRight: AccessRights.commonModules.partners.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: PartnerEditionPage,
						exact: true,
						path: keys.commonModules.partners.partnerEdition,
					},
				],
			},
			// RelationTypes
			{
				path: keys.commonModules.relationTypes.default,
				accessRight: AccessRights.commonModules.relationTypes.VIEW,
				routes: [
					{
						path: keys.commonModules.relationTypes.list,
						accessRight: AccessRights.commonModules.relationTypes.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RelationTypeListPage,
						exact: true,
					},
					{
						path: keys.commonModules.relationTypes.relationTypeCreation,
						accessRight: AccessRights.commonModules.relationTypes.enhancedRights.CREATE_RELATION_TYPE,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RelationTypeCreationPage,
						exact: true,
					},
					{
						path: keys.commonModules.relationTypes.relationTypeEdition,
						accessRight: AccessRights.commonModules.relationTypes.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RelationTypeEditionPage,
						exact: true,
					},
				],
			},
			// sectors
			{
				path: keys.commonModules.sectors.default,
				accessRight: AccessRights.commonModules.sectors.VIEW,
				routes: [
					{
						path: keys.commonModules.sectors.list,
						accessRight: AccessRights.commonModules.sectors.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: SectorListPage,
						exact: true,
					},
					{
						path: keys.commonModules.sectors.sectorCreation,
						accessRight: AccessRights.commonModules.sectors.enhancedRights.CREATE_SECTOR,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: SectorCreationPage,
						exact: true,
					},
					{
						path: keys.commonModules.sectors.sectorEdition,
						accessRight: AccessRights.commonModules.sectors.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: SectorEditionPage,
						exact: true,
					},
				],
			},
			// registrations
			{
				path: keys.commonModules.registrations.default,
				accessRight: AccessRights.commonModules.registrations.VIEW,
				routes: [
					{
						path: keys.commonModules.registrations.list,
						accessRight: AccessRights.commonModules.registrations.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RegistrationListPage,
						exact: true,
					},
					{
						path: keys.commonModules.registrations.registrationCreation,
						accessRight: AccessRights.commonModules.registrations.enhancedRights.CREATE_REGISTRATION,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RegistrationCreationPage,
						exact: true,
					},
					{
						path: keys.commonModules.registrations.registrationEdition,
						accessRight: AccessRights.commonModules.registrations.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: RegistrationEditionPage,
						exact: true,
					},
				],
			},
		],
	},
	// Define password
	{
		path: keys.user.definePassword,
		allowAnonymous: true,
		allowAuthenticated: false,
		superAdmin: false,
		exact: false,
		component: DefinePasswordPage,
	},
	// Human resources
	{
		path: keys.humanResources.default,
		accessRight: AccessRights.humanResources.VIEW,
		routes: [
			// Access definitions
			{
				path: keys.humanResources.accessDefinitions.default,
				accessRight: AccessRights.humanResources.roles.VIEW,
				routes: [
					{
						path: keys.humanResources.accessDefinitions.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: AccessDefinitionListPage,
						accessRight: AccessRights.humanResources.roles.VIEW,
					},
					{
						path: keys.humanResources.accessDefinitions.accessDefinitionCreation,
						accessRight: AccessRights.humanResources.roles.enhancedRights.CREATE_ACCESS_DEFINITION,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: AccessDefinitionCreationPage,
					},
					{
						path: keys.humanResources.accessDefinitions.accessDefinitionEdition,
						accessRight: AccessRights.humanResources.roles.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: AccessDefinitionEditionPage,
					},
				],
			},
			// Users
			{
				path: keys.humanResources.users.default,
				accessRight: AccessRights.humanResources.users.VIEW,
				routes: [
					{
						accessRight: AccessRights.humanResources.users.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: UserListPage,
						exact: true,
						path: keys.humanResources.users.list,
					},
					{
						accessRight: AccessRights.humanResources.users.enhancedRights.CREATE_USER,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: UserCreationPage,
						exact: true,
						path: keys.humanResources.users.userCreation,
					},
					{
						accessRight: AccessRights.humanResources.users.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: UserEditionPage,
						exact: true,
						path: keys.humanResources.users.userEdition,
					},
				],
			},
			// Expenses
			{
				path: keys.humanResources.expenses.default,
				accessRight: AccessRights.humanResources.expenses.VIEW,
				routes: [
					{
						path: keys.humanResources.expenses.list,
						accessRight: AccessRights.humanResources.expenses.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ExpenseListPage,
						exact: true,
					},
					{
						path: keys.humanResources.expenses.expenseCreation,
						accessRight: AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ExpenseCreationPage,
						exact: true,
					},
					{
						path: keys.humanResources.expenses.expenseEdition,
						accessRight: AccessRights.humanResources.expenses.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ExpenseEditionPage,
						exact: true,
					},
					{
						path: keys.humanResources.expenses.expensePreCreation,
						accessRight: AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ExpensePreCreationPage,
						exact: true,
					},
					{
						path: keys.humanResources.expenses.expenseVisualisation,
						accessRight: AccessRights.humanResources.expenses.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ExpenseVisualisationPage,
						exact: true,
					},
				],
			},
			// Timesheet
			{
				path: keys.humanResources.timesheets.default,
				accessRight: AccessRights.humanResources.timesheets.VIEW,
				routes: [
					{
						path: keys.humanResources.timesheets.list,
						accessRight: AccessRights.humanResources.timesheets.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TimesheetListPage,
						exact: true,
					},
					{
						path: keys.humanResources.timesheets.timesheetCreation,
						accessRight: AccessRights.humanResources.timesheets.enhancedRights.CREATE_TIMESHEET,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TimesheetCreationPage,
						exact: true,
					},
					{
						path: keys.humanResources.timesheets.timesheetEdition,
						accessRight: AccessRights.humanResources.timesheets.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TimesheetEditionPage,
						exact: true,
					},
				],
			},
		],
	},
	// Operations
	{
		path: keys.operations.default,
		accessRight: AccessRights.operations.VIEW,
		routes: [
			// Projects
			{
				path: keys.operations.projects.default,
				accessRight: AccessRights.operations.projects.VIEW,
				routes: [
					{
						path: keys.operations.projects.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ProjectListPage,
						accessRight: AccessRights.operations.projects.VIEW,
					},
					{
						path: keys.operations.projects.projectCreation,
						accessRight: AccessRights.operations.projects.enhancedRights.CREATE_PROJECT,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: ProjectCreationPage,
					},
					{
						path: keys.operations.projects.projectEdition,
						accessRight: AccessRights.operations.projects.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: ProjectEditionPage,
					},
				],
			},
			// Targeting
			{
				path: keys.operations.targetings.default,
				accessRight: AccessRights.operations.targetings.VIEW,
				routes: [
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetingListPage,
						exact: true,
						path: keys.operations.targetings.list,
					},
					{
						accessRight: AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetingCreationPage,
						exact: true,
						path: keys.operations.targetings.targetingCreation,
					},
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetingEditionPage,
						exact: true,
						path: keys.operations.targetings.targetingEdition,
					},
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetListPage,
						exact: true,
						path: keys.operations.targetings.targets.list,
					},
					{
						accessRight: AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TargetCreationPage,
						path: keys.operations.targetings.targets.targetCreation,
					},
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetEditionPage,
						exact: true,
						path: keys.operations.targetings.targets.targetEdition,
					},
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetPartnersListPage,
						exact: true,
						path: keys.operations.targetings.targetPartners.list,
					},
					{
						accessRight: AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: ImportTargetPartnersPage,
						exact: true,
						path: keys.operations.targetings.targets.targetLoadTargetPartners,
					},
					{
						accessRight: AccessRights.operations.targetings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TargetDetailsPage,
						exact: true,
						path: keys.operations.targetings.targets.targetPartners.targetPartnerEdition,
					},
				],
			},
		],
	},
	// Preferences
	{
		path: keys.user.preferences,
		allowAnonymous: false,
		allowAuthenticated: true,
		superAdmin: false,
		exact: true,
		component: UserPreferencesPage,
	},
	// Sales
	{
		path: keys.sales.default,
		accessRight: AccessRights.sales.VIEW,
		routes: [
			// Crm
			{
				path: keys.sales.crm.default,
				accessRight: AccessRights.sales.crm.VIEW,
				routes: [
					{
						path: keys.sales.crm.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: HistoryListPage,
						accessRight: AccessRights.sales.crm.VIEW,
					},
					{
						path: keys.sales.crm.historyReminder,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: HistoryReminderPage,
						accessRight: AccessRights.sales.crm.enhancedRights.CREATE_CRM,
					},
					{
						path: keys.sales.crm.historyCreation,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: HistoryCreationPage,
						accessRight: AccessRights.sales.crm.enhancedRights.CREATE_CRM,
					},
					{
						path: keys.sales.crm.historyEdition,
						accessRight: AccessRights.sales.crm.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: HistoryEditionPage,
					},
				],
			},
			// Offers
			{
				path: keys.sales.offers.default,
				accessRight: AccessRights.sales.offers.VIEW,
				routes: [
					{
						path: keys.sales.offers.list,
						accessRight: AccessRights.sales.offers.VIEW,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: OfferListPage,
					},
					{
						path: keys.sales.offers.offerCreation,
						accessRight: AccessRights.sales.offers.enhancedRights.CREATE_OFFER,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: OfferCreationPage,
					},
					{
						path: keys.sales.offers.offerEdition,
						accessRight: AccessRights.sales.offers.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: OfferEditionPage,
					},
				],
			},
			// Quotations
			{
				path: keys.sales.quotations.default,
				accessRight: AccessRights.sales.quotations.VIEW,
				routes: [
					{
						path: keys.sales.quotations.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: QuotationListPage,
						accessRight: AccessRights.sales.quotations.VIEW,
					},
					{
						path: keys.sales.quotations.quotationCreation,
						accessRight: AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: QuotationCreationPage,
					},
					{
						path: keys.sales.quotations.quotationEdition,
						accessRight: AccessRights.sales.quotations.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: QuotationEditionPage,
					},
					{
						path: keys.sales.quotations.quotationPreCreation,
						accessRight: AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: QuotationPreCreationPage,
					},
					{
						path: keys.sales.quotations.quotationVisualisation,
						accessRight: AccessRights.sales.quotations.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: QuotationVisualisationPage,
					},
				],
			},
		],
	},
	// Settings
	{
		path: keys.settings.default,
		accessRight: AccessRights.commonSettings.settings.VIEW,
		routes: [
			// StatusObjects
			{
				path: keys.settings.statusObjects.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.statusObjects.list,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: StatusObjectListPage,
						exact: true,
					},
					{
						path: keys.settings.statusObjects.statusObjectCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: StatusObjectCreationPage,
						exact: true,
					},
					{
						path: keys.settings.statusObjects.statusObjectEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: StatusObjectEditionPage,
						exact: true,
					},
				],
			},
			// Payment delays
			{
				path: keys.settings.paymentDelays.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.paymentDelays.list,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: PaymentDelayListPage,
					},
					{
						path: keys.settings.paymentDelays.paymentDelayCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: PaymentDelayCreationPage,
					},
					{
						path: keys.settings.paymentDelays.paymentDelayEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: PaymentDelayEditionPage,
					},
				],
			},
			// Number formats
			{
				path: keys.settings.numberFormats.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.numberFormats.list,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: NumberFormatListPage,
						exact: true,
					},
					{
						path: keys.settings.numberFormats.numberFormatCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: NumberFormatCreationPage,
						exact: true,
					},
					{
						path: keys.settings.numberFormats.numberFormatEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: NumberFormatEditionPage,
						exact: true,
					},
				],
			},
			// Vat rates
			{
				path: keys.settings.vatRates.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.vatRates.list,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: VatRateListPage,
						exact: true,
					},
					{
						path: keys.settings.vatRates.vatRateCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: VatRateCreationPage,
						exact: true,
					},
					{
						path: keys.settings.vatRates.vatRateEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: VatRateEditionPage,
						exact: true,
					},
				],
			},
			// Log
			{
				path: keys.settings.logs.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.logs.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: HistoryListPage,
						accessRight: AccessRights.commonSettings.settings.VIEW,
					},
				],
			},
			// Medias
			{
				path: keys.settings.medias.default,
				accessRight: AccessRights.commonSettings.mediaManager.VIEW,
				routes: [
					{
						path: keys.settings.medias.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: MediaListPage,
						accessRight: AccessRights.commonSettings.mediaManager.VIEW,
					},
				],
			},
			// Templates
			{
				path: keys.settings.templates.default,
				accessRight: AccessRights.commonSettings.templates.VIEW,
				routes: [
					{
						path: keys.settings.templates.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TemplateListPage,
						accessRight: AccessRights.commonSettings.templates.VIEW,
					},
					{
						path: keys.settings.templates.templatePreCreation,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePreCreationPage,
					},
					{
						path: keys.settings.templates.templatePdfEdition,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePdfEditionPage,
					},
					{
						path: keys.settings.templates.templatePdfExport,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePdfExportPage,
					},
					{
						path: keys.settings.templates.templatePdfExportFromApp,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePdfExportPage,
					},
					{
						path: keys.settings.templates.templatePdfView,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePdfViewPage,
					},
					{
						path: keys.settings.templates.templateSpreadSheetView,
						accessRight: AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplateSpreadSheetViewPage,
					},
					{
						path: keys.settings.templates.export,
						accessRight: AccessRights.commonSettings.templates.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: ExportPage,
					},
					{
						path: keys.settings.templates.exportList,
						accessRight: AccessRights.commonSettings.templates.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplateExportListPage,
					},
					{
						path: keys.settings.templates.reexport,
						accessRight: AccessRights.commonSettings.templates.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TemplatePdfReexportPage,
					},
				],
			},
			// Text objects
			{
				path: keys.settings.textObjects.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.textObjects.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: TextObjectListPage,
						accessRight: AccessRights.commonSettings.settings.VIEW,
					},
					{
						path: keys.settings.textObjects.textObjectCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TextObjectCreationPage,
					},
					{
						path: keys.settings.textObjects.textObjectEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: TextObjectEditionPage,
					},
				],
			},
			// Statuses
			{
				path: keys.settings.statuses.default,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				routes: [
					{
						path: keys.settings.statuses.list,
						exact: true,
						allowAnonymous: false,
						allowAuthenticated: true,
						component: StatusListPage,
						accessRight: AccessRights.commonSettings.settings.VIEW,
					},
					{
						path: keys.settings.statuses.statusCreation,
						accessRight: AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: StatusCreationPage,
					},
					{
						path: keys.settings.statuses.statusEdition,
						accessRight: AccessRights.commonSettings.settings.VIEW,
						allowAnonymous: false,
						allowAuthenticated: true,
						exact: true,
						component: StatusEditionPage,
					},
				],
			},
		],
	},
	// Super-admin
	{
		path: keys.superAdmin.default,
		routes: [
			{
				path: keys.superAdmin.default,
				allowAnonymous: false,
				allowAuthenticated: true,
				superAdmin: true,
				exact: true,
				component: AdminDashboardPage,
			},
			{
				path: keys.superAdmin.companyList,
				allowAnonymous: false,
				allowAuthenticated: true,
				superAdmin: true,
				exact: true,
				component: CompanyListPage,
			},
			{
				path: keys.superAdmin.companyCreation,
				allowAnonymous: false,
				allowAuthenticated: true,
				superAdmin: true,
				exact: true,
				component: CompanyCreationPage,
			},
			{
				path: keys.superAdmin.companyEdition,
				allowAnonymous: false,
				allowAuthenticated: true,
				superAdmin: true,
				exact: true,
				component: CompanyEditionPage,
			},
			{
				path: keys.superAdmin.settings,
				allowAnonymous: false,
				allowAuthenticated: true,
				superAdmin: true,
				exact: true,
				component: AdminSettingsPage,
			},
			{
				path: keys.superAdmin.templateList,
				exact: true,
				allowAnonymous: false,
				allowAuthenticated: true,
				component: TemplateListPage,
			},
			{
				path: keys.superAdmin.templatePreCreation,
				allowAnonymous: false,
				allowAuthenticated: true,
				exact: true,
				component: TemplatePreCreationPage,
			},
			{
				path: keys.superAdmin.templatePdfEdition,
				allowAnonymous: false,
				allowAuthenticated: true,
				exact: true,
				component: TemplatePdfEditionPage,
			},
			{
				path: keys.superAdmin.templatePdfView,
				allowAnonymous: false,
				allowAuthenticated: true,
				exact: true,
				component: TemplatePdfViewPage,
			},
			{
				path: keys.superAdmin.templateSpreadSheetView,
				allowAnonymous: false,
				allowAuthenticated: true,
				exact: true,
				component: TemplateSpreadSheetViewPage,
			},
		],
	},
];
