import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStatusObject, updateStatusObject } from 'redux/actions/statusObjects';
import { useCurrentStatusObjectSelector } from 'redux/selectors/statusObjects';
import routes from 'routes';

import { StatusObjectEditionForm } from 'components/statusObjects';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name StatusObjectEditionPage
 * @description A page used to edit an existing status object.
 *
 * @author Romaric Barthe
 */
const StatusObjectEditionPage = () => {
	const { t } = useTranslation();
	// const [, appSplitUrl] = history.location.pathname.split('/');
	// const appName = useMemo(() => camelcase(appSplitUrl), [appSplitUrl]);
	const { id: statusObjectId } = useParams();
	const dispatch = useDispatch();

	const statusObject = useCurrentStatusObjectSelector(statusObjectId);

	const canEditStatusObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	useEffect(() => {
		dispatch(fetchStatusObject(statusObjectId));
	}, [dispatch, statusObjectId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditStatusObject) {
			dispatch(updateStatusObject(formData, statusObjectId, routes.settings.statusObjects.list));
		} else {
			redirectOnSuccess(routes.settings.statusObjects.list);
		}
	}, [canEditStatusObject, dispatch, statusObjectId]);

	return (
		statusObject
			? <StatusObjectEditionForm statusObject={statusObject} onSubmit={handleFormSubmit} />
			: <p>{t('status_object.edition.loading')}</p>
	);
};

export default StatusObjectEditionPage;
