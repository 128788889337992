import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import camelcase from 'camelcase';
import { Environments, Modules } from 'constants/environmentEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchStatusObjectList } from 'redux/actions/statusObjects';
import { useStatusObjectListSelector, useStatusObjectTotalCountSelector } from 'redux/selectors/statusObjects';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { ActionsCell, NameCell } from 'components/statusObjects/statusObjectList';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name StatusObjectListPage
 * @description A list of all status objects accessible from the settings app in sub part status objects.
 *
 * From this page, the user is able to view, interact and search for status objects.
 *
 * @author Romaric Barthe
 */
const StatusObjectListPage = () => {
	const [, appSplitUrl] = history.location.pathname.split('/');
	const appName = useMemo(() => camelcase(appSplitUrl), [appSplitUrl]);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const statusObjects = useStatusObjectListSelector();
	const totalCount = useStatusObjectTotalCountSelector();

	const canCreateStatusObject = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);

	const headers = useMemo(() => [
		{ id: 'name', Cell: NameCell, Header: t('status_object.fields.status.name'), pinColumn: true, sorting: 'status.name' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('status_object.headers.actions') },
		{
			id: 'inDashboard',
			accessor: (row) => (row.inDashboard ? t('status_object.fields.inDashboard.yes') : t('status_object.fields.inDashboard.no')),
			Header: t('status_object.fields.inDashboard.header'),
			sorting: 'statusObject.inDashboard',
		},
		{
			id: 'favotite',
			accessor: (row) => (row.favorite ? t('status_object.fields.favorite.yes') : t('status_object.fields.favorite.no')),
			Header: t('status_object.fields.favorite.header'),
			sorting: 'statusObject.favorite',
		},
		{
			id: 'crossApplication',
			accessor: (row) => (row.crossApplication ? t('status_object.fields.crossApplication.yes') : t('status_object.fields.crossApplication.no')),
			Header: t('status_object.fields.crossApplication.header'),
			sorting: 'statusObject.crossApplication',
		},
		{
			id: 'automatic',
			accessor: (row) => (row.automatic ? t('status_object.fields.automatic.yes') : t('status_object.fields.automatic.no')),
			Header: t('status_object.fields.automatic.header'),
			sorting: 'statusObject.automatic',
		},
		{ id: 'description', accessor: (row) => row.status?.description ?? '', Header: t('status_object.fields.status.description'), sorting: 'status.description' },
		{ id: 'environment', accessor: 'environment', Header: t('status_object.fields.environment'), sorting: 'statusObject.environment' },
		{ id: 'rank', accessor: 'rank', Header: t('status_object.fields.rank'), sorting: 'statusObject.rank' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('status_object.fields.creation_date'), sorting: 'statusObject.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('status_object.fields.update_date'), sorting: 'statusObject.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('status_object.fields.creation_name'), sorting: 'statusObject.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('status_object.fields.update_name'), sorting: 'statusObject.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => {
		if (appName === `${Modules.HUMAN_RESOURCES}`) {
			const paramFiltered = {
				...params,
				filters: {
					conditions: [
						{ column: 'environment', criterion: 'is', value: Environments.EXPENSE },
					],
				},
			};
			dispatch(fetchStatusObjectList(paramFiltered));
		} else if (appName === `${Modules.OPERATIONS}`) {
			const paramFiltered = {
				...params,
				filters: {
					conditions: [
						{ column: 'environment', criterion: 'is', value: Environments.TARGETING },
					],
				},
			};
			dispatch(fetchStatusObjectList(paramFiltered));
		} else if (appName === `${Modules.ACCOUNTING}`) {
			const paramFiltered = {
				...params,
				filters: {
					conditions: [
						{ column: 'environment', criterion: 'is', value: Environments.INVOICE },
					],
				},
			};
			dispatch(fetchStatusObjectList(paramFiltered));
		} else {
			dispatch(fetchStatusObjectList(params));
		}
	});
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('statusObject');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.settings.statusObjects.statusObjectCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateStatusObject && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('status_object.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={statusObjects}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="statusObject"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('status_object.pages.list')} {...modalProps} />
		</div>
	);
};

export default StatusObjectListPage;
