import { useCallback } from 'react';
import { Edit2, Eye, Star, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { favoriteStatusObject, removeStatusObject } from 'redux/actions/statusObjects';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the StatusObjectListPage table. (favorite, edit and delete status object)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row							The row from which we retrieve the data to interact with.
 * @param {boolean}		row.original.favorite		The favorite status of the status object.
 * @param {string}		row.original.id				The id of the status object.
 * @param {object}		row.original.status			The status of the status object.
 * @param {string}		row.original.environment	The environment of the status object.
 * @param {function}	fetchData					The method to trigger if we need to refresh the parent table's data.
 */
const ActionsCell = ({ row: { original: { favorite, id, status, environment } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditStatusObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canDeleteStatusObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.DELETE_SETTING);

	const [favoriteButtonRef, favoriteTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	/**
	 * @function
	 * @name onFavoriteButtonClick
	 * @description Method triggered as a result to an onClick event from the favorite/unfavorite button.
	 *
	 * @author Romaric Barthe
	 */
	const onFavoriteButtonClick = useCallback(async () => {
		await dispatch(favoriteStatusObject({ id, status, environment }));
		fetchData();
	}, [dispatch, environment, fetchData, id, status]);

	/**
	 * @function
	 * @name onDeletionModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeletionModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeStatusObject({ id, status, environment }));
		fetchData();
	}, [dispatch, environment, fetchData, id, status, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.settings.statusObjects.statusObjectEdition, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditStatusObject ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditStatusObject ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditStatusObject && (
				<>
					<Button className="icon-only" onClick={onFavoriteButtonClick} ref={favoriteButtonRef}>
						<Star className={favorite ? 'orange' : ''} />
					</Button>
					<Tooltip {...favoriteTooltipProps}>
						{t('actions.favorite')}
					</Tooltip>
				</>
			)}
			{canDeleteStatusObject && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('status_object.deletion.confirmation_modal.content')}
						confirm={onDeletionModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmStatus={t('status_object.deletion.confirmation_modal.confirm')}
						cancelStatus={t('status_object.deletion.confirmation_modal.cancel')}
						titleStatus={t('status_object.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			favorite: PropTypes.bool.isRequired,
			status: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}).isRequired,
			environment: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
