import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatNumberFormatsPreview } from 'lib/numberFormats/formatNumberFormatsData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useNumberFormatListSelector, useNumberFormatTotalCountSelector } from 'redux/selectors/numberFormats';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/numberFormats/numberFormatList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { environmentTypes } from '../../components/numberFormats/function';
import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name NumberFormatListPage
 * @description A list of all number formats accessible from the Setting app in sub part number formats.
 *
 * From this page, the user is able to view, interact and search for number formats.
 *
 * @author Audrey Clerc
 */
const NumberFormatListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const numberFormats = useNumberFormatListSelector();
	const totalCount = useNumberFormatTotalCountSelector();
	const numberFormatAccessRight = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canCreateNumberFormat = useMemo(
		() => numberFormatAccessRight && numberFormats.length < environmentTypes(t).length, [numberFormatAccessRight, numberFormats.length, t]
	);

	const headers = useMemo(() => [
		{ id: 'preview', accessor: (row) => formatNumberFormatsPreview(row), Header: t('number_format.fields.preview'), pinColumn: true, sorting: 'numberFormat.rootName' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('number_format.headers.actions') },
		{
			id: 'environment',
			accessor: (row) => t(`number_format.fields.environment_type.${row.environment}`),
			Header: t('number_format.fields.environment'),
			sorting: 'number_format.environment',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('number_format.fields.creation_date'), sorting: 'numberFormat.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('number_format.fields.update_date'), sorting: 'numberFormat.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('number_format.fields.creation_name'), sorting: 'numberFormat.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('number_format.fields.update_name'), sorting: 'numberFormat.updatedBy' },
	], [t]);

	const { tableProps, modalProps } = useTableUserPreferences('numberFormat');
	const [newButtonRef, newTooltipProps] = useTooltip();
	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchNumberFormatList(params)));

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.settings.numberFormats.numberFormatCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{ canCreateNumberFormat && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('number_format.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={numberFormats}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="number_format"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('number_format.pages.list')} {...modalProps} />
		</div>
	);
};

export default NumberFormatListPage;
