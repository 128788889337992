import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatPaymentDelayName } from 'lib/paymentDelays/formatPaymentDelayData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchPaymentDelayList } from 'redux/actions/paymentDelays';
import { usePaymentDelayListSelector, usePaymentDelayTotalCountSelector } from 'redux/selectors/paymentDelays';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell } from 'components/paymentDelays/paymentDelayList';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name PaymentDelayListPage
 * @description A list of all payment delays accessible from the settings app in sub part payment delays.
 *
 * From this page, the user is able to view, interact and search for payment delays.
 *
 * @author Matthieu Schaerlinger
 */
const PaymentDelayListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const paymentDelays = usePaymentDelayListSelector();
	const totalCount = usePaymentDelayTotalCountSelector();

	const canCreatePaymentDelay = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	const headers = useMemo(() => [
		{
			id: 'name',
			accessor: (row) => (formatPaymentDelayName(row)),
			Header: t('payment_delay.fields.name'),
			sorting: 'paymentDelay.name',
		},
		{
			id: 'actions',
			Header: '',
			pinColumn: true,
			Cell: ActionsCell,
			name: t('payment_delay.headers.actions'),
		},
		{
			id: 'archived',
			accessor: (row) => (t(`payment_delay.fields.archived.${row.archived ? 'yes' : 'no'}`)),
			Header: t('payment_delay.fields.archived.header'),
			sorting: 'paymentDelay.archived',
		},
		{
			id: 'favorite',
			accessor: (row) => (t(`payment_delay.fields.favorite.${row.favorite ? 'yes' : 'no'}`)),
			Header: t('payment_delay.fields.favorite.header'),
			sorting: 'paymentDelay.favorite',
		},
		{ accessor: 'number', Header: t('payment_delay.fields.number'), sorting: 'paymentDelay.number' },
		{
			id: 'scale',
			accessor: (row) => (t(`payment_delay.scale.${row.scale}`)),
			Header: t('payment_delay.fields.scale'),
			sorting: 'paymentDelay.scale',
		},
		{
			id: 'term',
			accessor: (row) => (t(`payment_delay.term.${row.term}`)),
			Header: t('payment_delay.fields.term'),
			sorting: 'paymentDelay.term',
		},
		{
			id: 'createdAt',
			accessor: (row) => formatCreatedAt(row),
			Header: t('payment_delay.fields.creation_date'),
			sorting: 'paymentDelay.createdAt',
		},
		{
			id: 'updatedAt',
			accessor: (row) => formatUpdatedAt(row),
			Header: t('payment_delay.fields.update_date'),
			sorting: 'paymentDelay.updatedAt',
		},
		{
			id: 'createdBy',
			accessor: (row) => formatCreatedBy(row),
			Header: t('payment_delay.fields.creation_name'),
			sorting: 'paymentDelay.createdBy',
		},
		{
			id: 'updatedBy',
			accessor: (row) => formatUpdatedBy(row),
			Header: t('payment_delay.fields.update_name'),
			sorting: 'paymentDelay.updatedBy',
		},
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchPaymentDelayList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('paymentDelay');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.settings.paymentDelays.paymentDelayCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreatePaymentDelay && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('payment_delay.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={paymentDelays}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="paymentDelay"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('payment_delay.pages.list')} {...modalProps} />
		</div>
	);
};

export default PaymentDelayListPage;
