import { useCallback } from 'react';
import { Archive, Edit2, Eye, Star, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archiveVatRate, favoriteVatRate, removeVatRate, restoreVatRate } from 'redux/actions/vatRates';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the VatRateListPage table. (archive, favorite, delete and edit vat rate)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {boolean}		row.original.archived	The archived status of the vat rate.
 * @param {boolean}		row.original.favorite	The favorite status of the vat rate.
 * @param {string}		row.original.id			The id of the vat rate.
 * @param {number}		row.original.rate		The vat rate's value.
 * @param {function}	fetchData				The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { archived, favorite, id, rate } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [favoriteButtonRef, favoriteTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive button.
	 *
	 * @author Romaric Barthe
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (archived) {
			await dispatch(restoreVatRate({ id, rate }));
		} else {
			await dispatch(archiveVatRate({ id, rate }));
		}

		fetchData();
	}, [toggleArchiveModal, archived, fetchData, dispatch, id, rate]);

	/**
	 * @function
	 * @name onFavoriteButtonClick
	 * @description Method triggered as a result to an onClick event from the favorite/unfavorite button.
	 *
	 * @author Romaric Barthe
	 */
	const onFavoriteButtonClick = useCallback(async () => {
		await dispatch(favoriteVatRate({ id, rate }));
		fetchData();
	}, [dispatch, fetchData, id, rate]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeVatRate({ id, rate }));
		fetchData();
	}, [dispatch, fetchData, id, rate, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.settings.vatRates.vatRateEdition, { id }));
	}, [id]);

	const canEditVatRate = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canDeleteVatRate = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.DELETE_SETTING);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditVatRate && !archived ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditVatRate && !archived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditVatRate && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={archived
							? t('vat_rate.restoring.confirmation_modal.content')
							: t('vat_rate.archiving.confirmation_modal.content')}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={archived
							? t('vat_rate.restoring.confirmation_modal.confirm')
							: t('vat_rate.archiving.confirmation_modal.confirm')}
						cancelText={archived
							? t('vat_rate.restoring.confirmation_modal.cancel')
							: t('vat_rate.archiving.confirmation_modal.cancel')}
						titleText={archived
							? t('vat_rate.restoring.confirmation_modal.title')
							: t('vat_rate.archiving.confirmation_modal.title')}
					/>
				</>
			)}
			{canEditVatRate && !archived && (
				<>
					<Button className="icon-only" onClick={onFavoriteButtonClick} ref={favoriteButtonRef}>
						<Star className={favorite ? 'orange' : ''} />
					</Button>
					<Tooltip {...favoriteTooltipProps}>
						{t('actions.favorite')}
					</Tooltip>
				</>
			)}
			{canDeleteVatRate && !archived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('vat_rate.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('vat_rate.deletion.confirmation_modal.confirm')}
						cancelText={t('vat_rate.deletion.confirmation_modal.cancel')}
						titleText={t('vat_rate.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			favorite: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
			rate: PropTypes.number.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
