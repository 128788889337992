import conf from 'conf';
import { AccessRights, checkUserForAccessRight } from 'lib/shared/accessRights';
import { isEmpty } from 'lodash';

import keys from './keys';

const routesSignatures = {
	authentication: {
		path: keys.auth.default,
		accessRight: null,
		routes: [
			{
				path: keys.auth.login,
			},
			{
				path: keys.auth.logout,
			},
			{
				path: keys.auth.forgottenPassword,
			},
		],
	},
	commonModules: {
		path: keys.commonModules.default,
		name: 'pages.common_modules.default',
		accessRight: AccessRights.commonModules.VIEW,
		iconPath: `${conf.iconsPath}app_common_modules.svg`,
		routes: [
			{
				path: keys.commonModules.contacts.list,
				name: 'pages.common_modules.contacts.list',
				accessRight: AccessRights.commonModules.contacts.VIEW,
			},
			{
				path: keys.commonModules.partners.list,
				name: 'pages.common_modules.partners.list',
				accessRight: AccessRights.commonModules.partners.VIEW,
			},
			{
				path: keys.commonModules.relationTypes.list,
				name: 'pages.common_modules.relation_types.list',
				accessRight: AccessRights.commonModules.relationTypes.VIEW,
			},
			{
				path: keys.commonModules.sectors.list,
				name: 'pages.common_modules.sectors.list',
				accessRight: AccessRights.commonModules.sectors.VIEW,
			},
			{
				path: keys.commonModules.registrations.list,
				name: 'pages.common_modules.registrations.list',
				accessRight: AccessRights.commonModules.registrations.VIEW,
			},
		],
	},
	definePassword: {
		path: keys.user.definePassword,
		name: 'pages.define_password',
	},
	operations: {
		path: keys.operations.default,
		name: 'pages.operations.default',
		accessRight: AccessRights.operations.VIEW,
		iconPath: `${conf.iconsPath}app_operations.svg`,
		routes: [
			{
				path: keys.operations.projects.list,
				name: 'pages.operations.projects.list',
				accessRight: AccessRights.operations.projects.VIEW,
			},
			{
				path: keys.operations.targetings.list,
				name: 'pages.operations.targetings.list',
				accessRight: AccessRights.operations.targetings.VIEW,
			},
		],
	},
	humanResources: {
		path: keys.humanResources.default,
		name: 'pages.human_resources.default',
		accessRight: AccessRights.humanResources.VIEW,
		iconPath: `${conf.iconsPath}app_human_resources.svg`,
		routes: [
			{
				path: keys.humanResources.accessDefinitions.list,
				name: 'pages.human_resources.access_definitions.list',
				accessRight: AccessRights.humanResources.roles.VIEW,
			},
			{
				path: keys.humanResources.users.list,
				name: 'pages.human_resources.users.list',
				accessRight: AccessRights.humanResources.users.VIEW,
			},
			{
				path: keys.humanResources.expenses.list,
				name: 'pages.human_resources.expenses.list',
				accessRight: AccessRights.humanResources.expenses.VIEW,
			},
		],
	},
	preferences: {
		path: keys.user.preferences,
		name: 'pages.user.preferences',
	},
	sales: {
		path: keys.sales.default,
		name: 'pages.sales.default',
		iconPath: `${conf.iconsPath}app_sales.svg`,
		accessRight: AccessRights.sales.VIEW,
		routes: [
			{
				path: keys.sales.crm.list,
				accessRight: AccessRights.sales.crm.VIEW,
				name: 'pages.sales.crm.list',
			},
			{
				path: keys.sales.offers.list,
				name: 'pages.sales.offers.list',
				accessRight: AccessRights.sales.offers.VIEW,
			},
			{
				path: keys.sales.quotations.list,
				name: 'pages.sales.quotations.list',
				accessRight: AccessRights.sales.quotations.VIEW,
			},
		],
	},
	accounting: {
		accessRight: AccessRights.accounting.VIEW,
		iconPath: `${conf.iconsPath}app_accounting.svg`,
		name: 'pages.accounting.default',
		path: keys.accounting.default,
		routes: [
			{
				path: keys.accounting.invoices.dashboard,
				name: 'pages.accounting.invoices.dashboard',
				accessRight: AccessRights.accounting.invoices.VIEW,
			},
			{
				path: keys.accounting.invoices.list,
				name: 'pages.accounting.invoices.list',
				accessRight: AccessRights.accounting.invoices.VIEW,
			},
			{
				path: keys.accounting.accounts.list,
				name: 'pages.accounting.accounts.list',
				accessRight: AccessRights.accounting.accounts.VIEW,
			},
		],
	},
	settings: {
		path: keys.settings.default,
		name: 'pages.settings.default',
		iconPath: `${conf.iconsPath}app_common_settings.svg`,
		accessRight: AccessRights.commonSettings.settings.VIEW,
		routes: [
			{
				path: keys.settings.statusObjects.list,
				name: 'pages.settings.status_objects.list',
				accessRight: AccessRights.commonSettings.mediaManager.VIEW,
			},
			{
				path: keys.settings.vatRates.list,
				name: 'pages.settings.vat_rates.list',
				accessRight: AccessRights.commonSettings.mediaManager.VIEW,
			},
			{
				path: keys.settings.numberFormats.list,
				name: 'pages.settings.number_formats.list',
				accessRight: AccessRights.commonSettings.mediaManager.VIEW,
			},
			{
				path: keys.settings.medias.list,
				name: 'pages.settings.medias.list',
				accessRight: AccessRights.commonSettings.mediaManager.VIEW,
			},
			{
				path: keys.settings.textObjects.list,
				name: 'pages.settings.text_objects.list',
				accessRight: AccessRights.commonSettings.settings.VIEW,
			},
			{
				path: keys.settings.statuses.list,
				name: 'pages.settings.statuses.list',
				accessRight: AccessRights.commonSettings.settings.VIEW,
			},
			{
				path: keys.settings.templates.list,
				name: 'pages.settings.templates.list',
				accessRight: AccessRights.commonSettings.templates.VIEW,
			},
			{
				path: keys.settings.templates.exportList,
				name: 'pages.settings.templates.export_list',
				accessRight: AccessRights.commonSettings.templates.VIEW,
			},
			{
				path: keys.settings.templates.export,
				name: 'pages.settings.templates.export',
				accessRight: AccessRights.commonSettings.templates.VIEW,
			},
			{
				path: keys.settings.logs.list,
				accessRight: AccessRights.commonSettings.settings.VIEW,
				name: 'pages.settings.logs.list',
			},
		],
	},
	superAdmin: {
		path: keys.superAdmin.default,
		name: 'pages.super_admin.default',
		routes: [
			{
				path: keys.superAdmin.companyList,
				name: 'pages.super_admin.companies.list',
			},
			{
				path: keys.superAdmin.companyCreation,
				name: 'pages.super_admin.companies.creation',
			},
			{
				path: keys.superAdmin.companyEdition,
				name: 'pages.super_admin.companies.edition',
			},
			{
				path: keys.superAdmin.templateList,
				name: 'pages.super_admin.templates.list',
			},
			{
				path: keys.superAdmin.templatePreCreation,
				name: 'pages.super_admin.templates.pdf.creation',
			},
			{
				path: keys.superAdmin.settings,
				name: 'pages.super_admin.settings',
			},
		],
	},
};

/**
 * @function
 * @name getAvailableRoutingForUser
 * @description Returns the list of routes available to the user, depending on their access rights.
 *
 * @author Timothée Simon-Franza
 * @author Matthieu Schaerlinger
 *
 * @param {object}	user					The current user.
 * @param {object}	[submenu=undefined]		The submenu to used instead of the global routing object, when used recursively.
 *
 * @returns {object}
 */
const getAvailableRoutingForUser = (user, activatedModules, submenu = undefined) => {
	if (user?.superadmin) {
		return {
			superAdmin: {
				...routesSignatures.superAdmin,
				routes: routesSignatures.superAdmin.routes.filter(((route) => !(/:id$/.test(route.path)))),
			},
		};
	}

	const { superAdmin, ...routes } = submenu ?? routesSignatures;
	const result = {};
	let tmp = {};

	const authorizedRoutesForUser = Object.entries(routes).filter(([, module]) => activatedModules.includes(module.accessRight));

	authorizedRoutesForUser.forEach(([moduleKey, module]) => {
		if (!checkUserForAccessRight(user, module.accessRight)) {
			return;
		}

		tmp = {
			path: module.path,
			name: module.name,
			iconPath: module.iconPath,
			accessRight: module.accessRight,
			routes: module.routes?.filter((route) => !(/:id$/.test(route.path)) && checkUserForAccessRight(user, route.accessRight)),
			submenus: module.submenus ? getAvailableRoutingForUser(user, activatedModules, module.submenus) : undefined,
		};

		if (!isEmpty(tmp.routes) || !isEmpty(tmp.submenus ?? {})) {
			result[moduleKey] = { ...tmp };
		}
	});

	return result;
};

export {
	getAvailableRoutingForUser,
	routesSignatures,
};
