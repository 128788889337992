import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNumberFormat } from 'redux/actions/numberFormats';
import routes from 'routes';

import { NumberFormatCreationForm } from 'components/numberFormats';

/**
 * @name NumberFormatCreationPage
 * @description A page used to create a new number format.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 */
const NumberFormatCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addNumberFormat(formData, routes.settings.numberFormats.list));
	}, [dispatch]);

	return (
		<NumberFormatCreationForm onSubmit={handleFormSubmit} />
	);
};

export default NumberFormatCreationPage;
