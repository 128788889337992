import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPaymentDelay, updatePaymentDelay } from 'redux/actions/paymentDelays';
import { useCurrentPaymentDelaySelector } from 'redux/selectors/paymentDelays';
import routes from 'routes';

import { PaymentDelayEditionForm } from 'components/paymentDelays';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name PaymentDelayEditionPage
 * @description A page used to edit an existing payment delay.
 *
 * @author Matthieu Schaerlinger
 */
const PaymentDelayEditionPage = () => {
	const { id: paymentDelayId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const paymentDelay = useCurrentPaymentDelaySelector(paymentDelayId);

	const canEditPaymentDelay = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !paymentDelay?.archived;

	useEffect(() => {
		dispatch(fetchPaymentDelay(paymentDelayId));
	}, [dispatch, paymentDelayId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditPaymentDelay) {
			dispatch(updatePaymentDelay(
				{ ...formData, number: parseInt(formData.number, 10) },
				paymentDelayId,
				routes.settings.paymentDelays.list
			));
		} else {
			redirectOnSuccess(routes.settings.paymentDelays.list);
		}
	}, [canEditPaymentDelay, dispatch, paymentDelayId]);

	return (
		paymentDelay
			? <PaymentDelayEditionForm paymentDelay={paymentDelay} onSubmit={handleFormSubmit} />
			: <p>{t('payment_delay.edition.loading')}</p>
	);
};

export default PaymentDelayEditionPage;
